// Fluid grid system extracted from bootstrap

html body {
  *.nomargin{
    margin:0;
  }
  *.nopadding{
    padding:0;
  }
  .underline{
    text-decoration:underline;
  }
}

// Basic container for all centered content
%content {
  width: 1004px;
  margin: 0 auto;
  position: relative;
  /*@media #{$media-ipad} {
  }*/
  /*@media #{$media-desktop} {
    width: 960px;
  }
  @media #{$media-large} {
    width: 1160px;
  }*/
}

.contents {
  @extend %content;
  //padding-bottom: 20px;
}

.row:before,
.row:after {
  display: table;
  content: " ";
}

.row:after {
  clear: both;
}

.row:before,
.row:after {
  display: table;
  content: " ";
}

.row:after {
  clear: both;
}

.table-striped{
  tbody{
    tr.not-striped{
      td{
        background: transparent none;
      }
    }
    tr.striped{
      td{
        background: #f9f9f9 none;
      }
    }
  }
}

@media #{$media-ipad-and-up} {
  .row {
    margin-right: -$spacer;
    margin-left: -$spacer;
  }
}

.row .row {
  margin-right: -$spacer;
  margin-left: -$spacer;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: $spacer;
  padding-left: $spacer;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  float: left;
}

.col-1 { width: 8.333333333333332%; }
.col-2 { width: 16.666666666666664%; }
.col-3 { width: 25%; }
.col-4 { width: 33.33333333333333%; }
.col-5 { width: 41.66666666666667%; }
.col-6 { width: 50%; }
.col-7 { width: 58.333333333333336%; }
.col-8 { width: 66.66666666666666%; }
.col-9 { width: 75%; }
.col-10 { width: 83.33333333333334%; }
.col-11 { width: 91.66666666666666%; }
.col-12 { width: 100%; }

@media #{$media-above-phone} {
  .col-xm-1,
  .col-xm-2,
  .col-xm-3,
  .col-xm-4,
  .col-xm-5,
  .col-xm-6,
  .col-xm-7,
  .col-xm-8,
  .col-xm-9,
  .col-xm-10,
  .col-xm-11,
  .col-xm-12 {
    float: left;
  }
  .hide-xm{
    display:none;
  }
  .col-xm-1 {
    width: 8.333333333333332%;
  }
  .col-xm-2 {
    width: 16.666666666666664%;
  }
  .col-xm-3 {
    width: 25%;
  }
  .col-xm-4 {
    width: 33.33333333333333%;
  }
  .col-xm-5 {
    width: 41.66666666666667%;
  }
  .col-xm-6 {
    width: 50%;
  }
  .col-xm-7 {
    width: 58.333333333333336%;
  }
  .col-xm-8 {
    width: 66.66666666666666%;
  }
  .col-xm-9 {
    width: 75%;
  }
  .col-xm-10 {
    width: 83.33333333333334%;
  }
  .col-xm-11 {
    width: 91.66666666666666%;
  }
  .col-xm-12 {
    width: 100%;
  }
  .col-xm-push-1 {
    left: 8.333333333333332%;
  }
  .col-xm-push-2 {
    left: 16.666666666666664%;
  }
  .col-xm-push-3 {
    left: 25%;
  }
  .col-xm-push-4 {
    left: 33.33333333333333%;
  }
  .col-xm-push-5 {
    left: 41.66666666666667%;
  }
  .col-xm-push-6 {
    left: 50%;
  }
  .col-xm-push-7 {
    left: 58.333333333333336%;
  }
  .col-xm-push-8 {
    left: 66.66666666666666%;
  }
  .col-xm-push-9 {
    left: 75%;
  }
  .col-xm-push-10 {
    left: 83.33333333333334%;
  }
  .col-xm-push-11 {
    left: 91.66666666666666%;
  }
  .col-xm-pull-1 {
    right: 8.333333333333332%;
  }
  .col-xm-pull-2 {
    right: 16.666666666666664%;
  }
  .col-xm-pull-3 {
    right: 25%;
  }
  .col-xm-pull-4 {
    right: 33.33333333333333%;
  }
  .col-xm-pull-5 {
    right: 41.66666666666667%;
  }
  .col-xm-pull-6 {
    right: 50%;
  }
  .col-xm-pull-7 {
    right: 58.333333333333336%;
  }
  .col-xm-pull-8 {
    right: 66.66666666666666%;
  }
  .col-xm-pull-9 {
    right: 75%;
  }
  .col-xm-pull-10 {
    right: 83.33333333333334%;
  }
  .col-xm-pull-11 {
    right: 91.66666666666666%;
  }
  .col-xm-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-xm-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-xm-offset-3 {
    margin-left: 25%;
  }
  .col-xm-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-xm-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-xm-offset-6 {
    margin-left: 50%;
  }
  .col-xm-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-xm-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-xm-offset-9 {
    margin-left: 75%;
  }
  .col-xm-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-xm-offset-11 {
    margin-left: 91.66666666666666%;
  }
}

@media #{$media-ipad} {
  .container {
    max-width: 728px;
  }
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-1 {
    width: 8.333333333333332%;
  }
  .col-sm-2 {
    width: 16.666666666666664%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-4 {
    width: 33.33333333333333%;
  }
  .col-sm-5 {
    width: 41.66666666666667%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-7 {
    width: 58.333333333333336%;
  }
  .col-sm-8 {
    width: 66.66666666666666%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-10 {
    width: 83.33333333333334%;
  }
  .col-sm-11 {
    width: 91.66666666666666%;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-push-1 {
    left: 8.333333333333332%;
  }
  .col-sm-push-2 {
    left: 16.666666666666664%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-4 {
    left: 33.33333333333333%;
  }
  .col-sm-push-5 {
    left: 41.66666666666667%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-7 {
    left: 58.333333333333336%;
  }
  .col-sm-push-8 {
    left: 66.66666666666666%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-10 {
    left: 83.33333333333334%;
  }
  .col-sm-push-11 {
    left: 91.66666666666666%;
  }
  .col-sm-pull-1 {
    right: 8.333333333333332%;
  }
  .col-sm-pull-2 {
    right: 16.666666666666664%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-4 {
    right: 33.33333333333333%;
  }
  .col-sm-pull-5 {
    right: 41.66666666666667%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-7 {
    right: 58.333333333333336%;
  }
  .col-sm-pull-8 {
    right: 66.66666666666666%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-10 {
    right: 83.33333333333334%;
  }
  .col-sm-pull-11 {
    right: 91.66666666666666%;
  }
  .col-sm-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-sm-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666666666666%;
  }
}

@media #{$media-phone} {
  .container {
    max-width: 728px;
  }
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    float: left;
  }
  .col-xs-1 {
    width: 8.333333333333332%;
  }
  .col-xs-2 {
    width: 16.666666666666664%;
  }
  .col-xs-3 {
    width: 25%;
  }
  .col-xs-4 {
    width: 33.33333333333333%;
  }
  .col-xs-5 {
    width: 41.66666666666667%;
  }
  .col-xs-6 {
    width: 50%;
  }
  .col-xs-7 {
    width: 58.333333333333336%;
  }
  .col-xs-8 {
    width: 66.66666666666666%;
  }
  .col-xs-9 {
    width: 75%;
  }
  .col-xs-10 {
    width: 83.33333333333334%;
  }
  .col-xs-11 {
    width: 91.66666666666666%;
  }
  .col-xs-12 {
    width: 100%;
  }
  .col-xs-push-1 {
    left: 8.333333333333332%;
  }
  .col-xs-push-2 {
    left: 16.666666666666664%;
  }
  .col-xs-push-3 {
    left: 25%;
  }
  .col-xs-push-4 {
    left: 33.33333333333333%;
  }
  .col-xs-push-5 {
    left: 41.66666666666667%;
  }
  .col-xs-push-6 {
    left: 50%;
  }
  .col-xs-push-7 {
    left: 58.333333333333336%;
  }
  .col-xs-push-8 {
    left: 66.66666666666666%;
  }
  .col-xs-push-9 {
    left: 75%;
  }
  .col-xs-push-10 {
    left: 83.33333333333334%;
  }
  .col-xs-push-11 {
    left: 91.66666666666666%;
  }
  .col-xs-pull-1 {
    right: 8.333333333333332%;
  }
  .col-xs-pull-2 {
    right: 16.666666666666664%;
  }
  .col-xs-pull-3 {
    right: 25%;
  }
  .col-xs-pull-4 {
    right: 33.33333333333333%;
  }
  .col-xs-pull-5 {
    right: 41.66666666666667%;
  }
  .col-xs-pull-6 {
    right: 50%;
  }
  .col-xs-pull-7 {
    right: 58.333333333333336%;
  }
  .col-xs-pull-8 {
    right: 66.66666666666666%;
  }
  .col-xs-pull-9 {
    right: 75%;
  }
  .col-xs-pull-10 {
    right: 83.33333333333334%;
  }
  .col-xs-pull-11 {
    right: 91.66666666666666%;
  }
  .col-xs-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-xs-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-xs-offset-3 {
    margin-left: 25%;
  }
  .col-xs-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-xs-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-xs-offset-6 {
    margin-left: 50%;
  }
  .col-xs-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-xs-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-xs-offset-9 {
    margin-left: 75%;
  }
  .col-xs-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-xs-offset-11 {
    margin-left: 91.66666666666666%;
  }
}

@media #{$media-desktop} {
  .container {
    max-width: 960px;
  }
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-1 {
    width: 8.333333333333332%;
  }
  .col-lg-2 {
    width: 16.666666666666664%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-4 {
    width: 33.33333333333333%;
  }
  .col-lg-5 {
    width: 41.66666666666667%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-7 {
    width: 58.333333333333336%;
  }
  .col-lg-8 {
    width: 66.66666666666666%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-10 {
    width: 83.33333333333334%;
  }
  .col-lg-11 {
    width: 91.66666666666666%;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-push-1 {
    left: 8.333333333333332%;
  }
  .col-lg-push-2 {
    left: 16.666666666666664%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-4 {
    left: 33.33333333333333%;
  }
  .col-lg-push-5 {
    left: 41.66666666666667%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-7 {
    left: 58.333333333333336%;
  }
  .col-lg-push-8 {
    left: 66.66666666666666%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-10 {
    left: 83.33333333333334%;
  }
  .col-lg-push-11 {
    left: 91.66666666666666%;
  }
  .col-lg-pull-1 {
    right: 8.333333333333332%;
  }
  .col-lg-pull-2 {
    right: 16.666666666666664%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-4 {
    right: 33.33333333333333%;
  }
  .col-lg-pull-5 {
    right: 41.66666666666667%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-7 {
    right: 58.333333333333336%;
  }
  .col-lg-pull-8 {
    right: 66.66666666666666%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-10 {
    right: 83.33333333333334%;
  }
  .col-lg-pull-11 {
    right: 91.66666666666666%;
  }
  .col-lg-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-lg-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666666666666%;
  }
}


@media #{$media-large} {
  .container {
    max-width: 1160px;
  }
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12 {
    float: left;
  }
  .col-xl-1 {
    width: 8.333333333333332%;
  }
  .col-xl-2 {
    width: 16.666666666666664%;
  }
  .col-xl-3 {
    width: 25%;
  }
  .col-xl-4 {
    width: 33.33333333333333%;
  }
  .col-xl-5 {
    width: 41.66666666666667%;
  }
  .col-xl-6 {
    width: 50%;
  }
  .col-xl-7 {
    width: 58.333333333333336%;
  }
  .col-xl-8 {
    width: 66.66666666666666%;
  }
  .col-xl-9 {
    width: 75%;
  }
  .col-xl-10 {
    width: 83.33333333333334%;
  }
  .col-xl-11 {
    width: 91.66666666666666%;
  }
  .col-xl-12 {
    width: 100%;
  }
  .col-xl-push-1 {
    left: 8.333333333333332%;
  }
  .col-xl-push-2 {
    left: 16.666666666666664%;
  }
  .col-xl-push-3 {
    left: 25%;
  }
  .col-xl-push-4 {
    left: 33.33333333333333%;
  }
  .col-xl-push-5 {
    left: 41.66666666666667%;
  }
  .col-xl-push-6 {
    left: 50%;
  }
  .col-xl-push-7 {
    left: 58.333333333333336%;
  }
  .col-xl-push-8 {
    left: 66.66666666666666%;
  }
  .col-xl-push-9 {
    left: 75%;
  }
  .col-xl-push-10 {
    left: 83.33333333333334%;
  }
  .col-xl-push-11 {
    left: 91.66666666666666%;
  }
  .col-xl-pull-1 {
    right: 8.333333333333332%;
  }
  .col-xl-pull-2 {
    right: 16.666666666666664%;
  }
  .col-xl-pull-3 {
    right: 25%;
  }
  .col-xl-pull-4 {
    right: 33.33333333333333%;
  }
  .col-xl-pull-5 {
    right: 41.66666666666667%;
  }
  .col-xl-pull-6 {
    right: 50%;
  }
  .col-xl-pull-7 {
    right: 58.333333333333336%;
  }
  .col-xl-pull-8 {
    right: 66.66666666666666%;
  }
  .col-xl-pull-9 {
    right: 75%;
  }
  .col-xl-pull-10 {
    right: 83.33333333333334%;
  }
  .col-xl-pull-11 {
    right: 91.66666666666666%;
  }
  .col-xl-offset-1 {
    margin-left: 8.333333333333332%;
  }
  .col-xl-offset-2 {
    margin-left: 16.666666666666664%;
  }
  .col-xl-offset-3 {
    margin-left: 25%;
  }
  .col-xl-offset-4 {
    margin-left: 33.33333333333333%;
  }
  .col-xl-offset-5 {
    margin-left: 41.66666666666667%;
  }
  .col-xl-offset-6 {
    margin-left: 50%;
  }
  .col-xl-offset-7 {
    margin-left: 58.333333333333336%;
  }
  .col-xl-offset-8 {
    margin-left: 66.66666666666666%;
  }
  .col-xl-offset-9 {
    margin-left: 75%;
  }
  .col-xl-offset-10 {
    margin-left: 83.33333333333334%;
  }
  .col-xl-offset-11 {
    margin-left: 91.66666666666666%;
  }
}
