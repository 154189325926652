//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  Layout
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
table{
  &.table-sortable{
    tbody > tr {
      cursor:move;
    }
  }
  &.table-gray{
    tbody > tr:hover{
      td{
        background-color:#f4f4f4;
      }
    }
  }
  tbody > tr:hover{
    td{
      background-color:#f4f4f4;
    }
  }
  &.noHover{
    tbody > tr:hover{
      td{
        background-color:inherit;
      }
    }
  }
}

.row-fluid{
  overflow:visible;
  &:after{
    clear: both;
  }
}

.c-inner {
  position:relative;
  margin: 0 auto;
  min-height: 600px;
  width: 100%;
  padding: $padder;
  background: $page-background;
  h2:first-child {
    padding: 15px 0px;
  }
  .page-header {
    margin: 0px;
    input, a.btn {
      margin-top: 14px;
    }
  }
}

.c-canvas {
  min-height: 500px;
  padding: $padder;
  border-radius: 0px 0px 5px 5px;
  h1, h2, h3 {
    margin: 0px;
  }
  body.login & {
    border-radius: 5px;
    margin: 30px auto 10px;
    min-height: 20px;
    width: 400px;
    position: relative;
    padding: 12px;
    &.missing{
      width:100%;
      max-width:1250px;
      min-height:200px;
    }
    h1, h2, h3 {
      margin: 12px 0px;
      text-align: center;
    }
    .input-group{
      &.first{
        margin-bottom: 1em;
      }
    }
  }
}

.c-footer {
  color:#333;font-size:12px;line-height:30px;padding:0px 12px;
}

.input-group{
  width:100%;
}

.c-tabs-sub-menu{
  background: $sidebar-color;
}

.payment-errors{
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
  padding: 1em;
  font-weight: bold;
  margin: 1em 0;
}

.chrono{
  font-weight: bold;
  #clock{
    font-size:1.2em;
    font-weight: bold;
  }
}

.parsley-error {
  border: 1px solid red;
}
