//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  Variables
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

$dark-writing: #333;
$second-color: rgb(248,152,41);

$width-sidebar: 280px;
$heightNavbar: 65px;

// Client konsole variables
//$colorful-custom: #1ba9c9;
$colorful-custom: #01ACED;

// Media quries breakpoints

$media-bkBootStrap: "(max-width: 1245px)";
$media-large-screen: "(min-width: 1280px) and (max-width: 1380px)";
$media-medium-screen: "(min-width: 1180px) and (max-width: 1280px)";
$media-little-screen: "(min-width: 768px) and (max-width: 1180px)";
$media-ipad: "(min-width: 768px) and (max-width: 991px)";
$media-ipad-and-up: "(min-width: 768px)";
$media-ipad-and-below: "(min-width: 0px) and (max-width: 768px)";
$media-desktop: "(min-width: 992px)";
$media-large: "(min-width: 1210px)";
$media-phone: "(max-width: 600px)";
$media-above-phone: "(min-width: 600px)";

// Layout vars
$spacer: 15px;
$padder: 20px;
$border-radius: 5px;
$border-radius-round: 500px;

// Fonts typo
$typo: 'Open Sans', Helvetica, Arial, sans-serif;
$titleTypo: 'Open Sans', Helvetica, Arial, sans-serif;

// Fonts colors
$light-color: #FFF;
$dark-color: #2D2D2D;
$base-color: #3d454d;
$copy-color: #A6A6A6;

$title-color: #526166;

// Console
$sidebar-color: #1f2226;

// Buttons colors
$colorful: #1dadea;
$colorful02: #0091cf;

$btn-color: $colorful;
$btn-color02: $colorful02;
$fill-btn-color: $light-color;
$btn-color-light: $light-color;
$fill-btn-color-light: $base-color;

$notif-label-color: #ee4923;

// Customs colors
$reserved-color: #A2AB13;
$reserved-color-pro: #1dadea;
$na-color: #A2AB13;

// Forms colors
$errors-color: #d50000;
$success-color: #32B100;

// Borders
$border-size: 1px;
$border-btn-size: 2px;
$border-color: #DCDCDC;

// Basics colors
$softgrey: #E9EAE4;
$page-background: #FFF;
$console-color: #E1E8EB;
$lightgrey: #F1F1F1;
$lightbluegrey:  lighten($base-color, 60%);
$lightgreyborder: #DADFE5;
$darkgrey: #2D2D2D;

// Fonts weights
$fw-xlight: 100;
$fw-light: 300;
$fw-normal: 400;
$fw-regular: 400;
$fw-medium: 400;
$fw-semibold: 600;
$fw-bold: 700;
$fw-xbold: 900;

// Fonts sizes
$size-xsmall: 0.7em;
$size-smaller: 0.8em;
$size-small: 0.9em;
$size-medium: 1.2em;
$size-med-large: 1.35em;
$size-large: 1.5em;
$size-larger: 1.8em;
$size-big: 2em;
$size-ultra: 2.5em;
$size-mega: 3em;
$size-giga: 4em;

/**** MIXIN'S ****/
%border-radius-none {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

%border-radius {
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
}

%border-radius-round {
  -webkit-border-radius: $border-radius-round;
  -moz-border-radius: $border-radius-round;
  border-radius: $border-radius-round;
}

%border-radius-bottom {
  -webkit-border-bottom-right-radius: $border-radius;
  -webkit-border-bottom-left-radius: $border-radius;
  -moz-border-radius-bottomright: $border-radius;
  -moz-border-radius-bottomleft: $border-radius;
  border-bottom-right-radius: $border-radius;
  border-bottom-left-radius: $border-radius;
}

%transition-all {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

%transition-all-smooth {
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -ms-transition: all 0.7s;
  -o-transition: all 0.7s;
  transition: all 0.7s;
}

%opacity-100 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  -moz-opacity: 1;
  -khtml-opacity: 1;
  opacity: 1;
}
%opacity-75 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
  filter: alpha(opacity=75);
  -moz-opacity: 0.75;
  -khtml-opacity: 0.75;
  opacity: 0.75;
}
%opacity-50 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  -moz-opacity: 0.5;
  -khtml-opacity: 0.5;
  opacity: 0.5;
}
%opacity-25 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
  filter: alpha(opacity=25);
  -moz-opacity: 0.25;
  -khtml-opacity: 0.25;
  opacity: 0.25;
}
%opacity-0 {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
}
