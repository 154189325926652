.dataTables_wrapper{
  .dataTables_info{
    height: 40px;
    margin: 20px 0;
  }
  .dataTables_length{
    label{
      line-height:34px;
      margin:0 0 20px 0;
    }
  }
  .dataTables_filter{
    label{
      margin:0 0 20px 0;
    }
    input{
      display: inline-block;
      width: auto;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857143;
      color: #555;
      background-color: #fff;
      background-image: none;
      border: 1px solid #ccc;
      border-radius: 4px;
      -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
      -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
      -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
      transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    }
  }
}
