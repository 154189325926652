.notifier-container {
  position: fixed;
  top: 15px;
  right: 15px;
  width:300px;
  z-index: 2000;
  box-sizing:border-box;
  text-align:center;
  .notifier-item {
    width: 100%;
    padding:1em;
    border-radius: 4px;
    border-top: 3px solid #ccc;
    &.notifier-success {
      color: #468847;
      background-color: #dff0d8;
      border-color: #d6e9c6;
    }
    &.notifier-danger {
      color: #b94a48;
      background-color: #f2dede;
      border-color: #eed3d7;
    }
    &.notifier-warning {
      color: #c09853;
      background-color: #fcf8e3;
      border-color: #fbeed5;
    }
    &.notifier-info{
      color: #3a87ad;
      background-color: #d9edf7;
      border-color: #bce8f1;
    }
    h4 {
      margin:0px;
      color: inherit;
    }
  }
}
