//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
//  Base
//~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

html, body {
  padding:0px;
  margin:0px;
  background:#ECEEF1;
}
.clear { clear:both; }
.text-right{
  min-width:200px;
}
.bgwhite {background:#fff;}
.c-canvas.c-inner .alert { margin-top:10px; }

.modal .modal-footer { padding:9px 10px 10px }

.c-canvas input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

img {
  max-width: 100%;
}

select{
  &.nomargin{
    margin:0;
    height:42px;
  }
}

.nomargin { margin: 0!important; }
.nomargintop { margin-top: 0!important; }

.panel-heading h4 { margin: 4px 0; }
.c-canvas.c-inner h4.header {
  padding: 4px 0;
  height: 38px;
  line-height: 38px;
  margin-top: 15px;
}

input[type=file] {
  display: inline-block;
  min-height: 34px;
  padding: 6px 9px;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: border linear 0.2s, box-shadow linear 0.2s;
     -moz-transition: border linear 0.2s, box-shadow linear 0.2s;
      -ms-transition: border linear 0.2s, box-shadow linear 0.2s;
       -o-transition: border linear 0.2s, box-shadow linear 0.2s;
          transition: border linear 0.2s, box-shadow linear 0.2s;
}

a {
  text-decoration: none;
  -webkit-transition: color .25s, background .25s, opacity .25s;
     -moz-transition: color .25s, background .25s, opacity .25s;
      -ms-transition: color .25s, background .25s, opacity .25s;
       -o-transition: color .25s, background .25s, opacity .25s;
          transition: color .25s, background .25s, opacity .25s;
}

// Space those action buttons
.table {
  a.btn {
    margin-left: 5px;
  }
}
.row.form-row {
  margin-top: 10px;
  .col.form-label {
    text-align: right;
    min-height: 38px;
    line-height: 38px;
    font-size: 16px;
    font-weight: normal;
  }
  input[type=text], input[type=password], input[type=file], select, textarea {
    border-width: 3px;
  }
  select{
    width:100%;
    border: 3px solid #ccc;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.075);
    -webkit-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  }
}

.form-control{
  min-height: 40px;
  padding: 8px 12px;
}

.inline-control{
  display: inline;
  width: auto;
}

// Add style for table on white backgrounds
table.table-gray {

  thead>tr>th, tbody>tr>th, tfoot>tr>th, thead>tr>td, tbody>tr>td, tfoot>tr>td {
    background:#ededed;
  }
  tbody>tr {
    padding-bottom: 5px;
    border-collapse:separate;
    border-spacing:5em;
  }
  tbody>tr>th, tbody>tr>td {
    background:#fafafa;
    border-top: 1px solid #dadfe5;
    border-bottom: 1px solid #dadfe5;
  }
}
.mce-container.mce-tinymce{
  .mce-panel{
    display:table;
    width:100%;
  }
}

// Little callout ! Like an alert but sexier!
.callout {
  margin: 20px 0;
  padding: 15px 30px 15px 15px;
  background-color: lighten($base-color, 70%);//#fcf2f2;
  border-left: 5px solid $base-color;//#df7c7b;
}
.callout h4 {
  margin-top: 0;
}
.callout p:last-child {
  margin-bottom: 0;
}
.callout code,
.callout .highlight {
  background-color: #fff;
}

//bordered div
.row div.bordered{
  border: 1px solid #eee;
  margin: 0;
  border-collapse: collapse;
  .borderer{
    padding: 1em;
  }
  .border-spacing{
    border-right: 1px solid #eee;
  }
}
@media (max-width: 600px) {
  input[type="file"]{
    width:100%;
  }
  .col-xs-12.nopadding_600, col-12.nopadding_600{
    padding-left:0;
    padding-right:0;
  }
  .row div.bordered{
    border: 1px solid #eee;
    .borderer{
      padding: 0.5em;
    }
    .border-spacing{
      border:0;
    }
  }
}

.alert {
  padding: 1.2em 3em 1.2em 1em;
  margin-bottom: 20px;
  color: #c09853;
  background-color: #fcf8e3;
  border: 1px solid #fbeed5;
  border-radius: 4px;
  font-weight:bold;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert hr {
  border-top-color: #f8e5be;
}

.alert .alert-link {
  font-weight: 500;
  color: #a47e3c;
}

.alert .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  color: #468847;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #356635;
}

.alert-danger {
  color: #b94a48;
  background-color: #f2dede;
  border-color: #eed3d7;
}

.alert-danger hr {
  border-top-color: #e6c1c7;
}

.alert-danger .alert-link {
  color: #953b39;
}

.alert-info {
  color: #3a87ad;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #2d6987;
}

.alert-block {
  padding-top: 15px;
  padding-bottom: 15px;
}

.alert-block > p,
.alert-block > ul {
  margin-bottom: 0;
}

.alert-block p + p {
  margin-top: 5px;
}

.parsley-errors-list{
  li, .parsley-required{
    color: red;
    font-weight: bold;
  }
}

.checkbox{
  .parsley-errors-list{
    margin:0;
  }
}

html body{
  .text-left{
    text-align:left !important;
  }
  .text-right{
    text-align:right !important;
  }
  .text-center{
    text-align:center !important;
  }
}

.gm-style{
  img{
    max-width:none;
  }
}

.mandatory{
  color:red;
}

.fa.red{
  color:red;
}

.fa.green{
  color:green;
}

.row.form-row select, .row.form-row input{
  &.parsley-error{
    border: 1px solid red;
    color: red;
  }
}
