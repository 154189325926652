$largeur-MenuMobile:75px;

@media #{$media-large-screen}{
  .body .c-sub-menu ul li a{
    font-size:$size-small;
  }
}
@media #{$media-medium-screen}{
  .body .c-sub-menu ul li a{
    font-size:$size-smaller;
  }
}
@media #{$media-little-screen}{
  .body .c-sub-menu{
    padding: 15px 15px 0 30px;
    ul li{
      margin-right:0;
      a{
        padding: 1.5em 1.2em;
        .name{display:none;}
      }
    }
  }
}
@media #{$media-ipad-and-below} {
  .sidebar{
    width:$largeur-MenuMobile;
    .avatar{
      .logo{
        padding:4px 0 0 0;
        width:45px;
        .logoDesktop{display:none;}
        .logoMobile{display:block;}
      }
    }
    .sidebar-scroll{
      padding-top:0;
      .menu{
        .icon-SideBar-Mobile{
          display:block;
        }
        li a{
          .name{display:none;}
          i{
            margin-left:3px;
          }
        }
      }
    }
  }
  .navbar.fixed{
    padding-left:$largeur-MenuMobile;
  }
  .body{
    padding-left:$largeur-MenuMobile;
    .c-sub-menu{
      padding: 15px 15px 0 30px;
      ul li{
        margin-right:0;
        a{
          padding: 1.5em 1.2em;
          .name{display:none;}
        }
      }
    }
  }
}
@media #{$media-phone} {
  .navbar .logo .title{font-size:$size-small;}
  .navbar.fixed .settings ul li a{width:40px;}
  .body .c-sub-menu ul li a{
    padding: 1.5em 0.8em;
  }
}
@media #{$media-ipad} {

}
@media #{$media-large} {

}

@media #{$media-bkBootStrap} {
 .responsiveGridBreak{width:100% !important;}
}
