///////////////////////////////////////////////////
// Basics /////////////////////////////////////////
///////////////////////////////////////////////////

::-webkit-input-placeholder{
  color: darken($lightbluegrey, 14%);
}

:-moz-placeholder { /* Firefox 18- */
   color: darken($lightbluegrey, 14%);
}

::-moz-placeholder {  /* Firefox 19+ */
   color: darken($lightbluegrey, 14%);
}

:-ms-input-placeholder {
   color: darken($lightbluegrey, 14%);
}

html{
  &.login-full{
    background: #565f63;
  }
  &.console-full{
    //background: $console-color;
    background: $light-color;
  }
}

.head{
  margin: 2em 0;
}

.d-table{
  display: table;
  width: 100%;
  height: 100%;
}

.t-cell{
  display: table-cell;
  vertical-align: middle;
}

.title, .subtitle, h1, h2, h3, h4, h5, h6{
  color: $title-color;
}

.label-text{
  margin-top: 15px;
  &.lg-label{
    margin-top: 20px;
  }
}

.spacer-md{
  width: 100%;
  height: 30px;
}

.spacer-sd{
  width: 100%;
  height: 15px;
}

.col-xs-collapse-right{
  padding-right: 0;
}

.col-xs-collapse-left{
  padding-left: 0;
}

.tipsy{
  font-size: 0.85em;
  max-width: 200px;
}

///////////////////////////////////////////////////
// Login css //////////////////////////////////////
///////////////////////////////////////////////////

body{
  //background: $console-color;
  background: $light-color;
}

body.login{
  background: url("/assets/img/console/backgrounds/bg-konsole-anekdotes-01.jpg") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  .logo{
    width: 187px;
    img{
      max-width: 100%;
    }
  }
  header{}
  footer{
    position: absolute;
    bottom: 0;
    left: 0;
    p, a{
      color: $light-color;
    }
    p, a{
      display: inline-block;
      @extend %opacity-50;
    }
    a{
      &:hover, &:focus{
        @extend %opacity-100;
      }
    }
  }
}

.inner-content{
  position: relative;
  padding: $spacer*3;
  height: 100%;
  width: 100%;
  .inner{
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.modal-container{
  //padding: 150px 0;
}

.modal-box{
  $width: 500px;
  $height: 500px;
  padding: 45px;
  width: $width;
  height: $height;
  background: #FFF;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -(calc($height/2));
  margin-left: -(calc($width/2));
  @extend %border-radius;
  .head{
    text-align: center;
    margin: 0 0 4em;
    .title{
      font-weight: $fw-light;
    }
  }
}

///////////////////////////////////////////////////
// Main console ///////////////////////////////////
///////////////////////////////////////////////////

#main-container{
  position: relative;
  width: 100%;
  height: 100%;
  overflow:hidden;
  //margin-left: $width-sidebar;
}

.sidebar{
  width: $width-sidebar;
  height: 100%;
  background: $sidebar-color;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 102;
  padding-top:$heightNavbar;
  overflow:hidden;
  &.small{
    width:75px;
    .avatar .logo{
      width:45px;
      padding:4px 0 0 0;
    }
    .avatar .logo .logoDesktop{display:none;}
    .avatar .logo .logoMobile{display:block;}
    .menu li a .name{display:none;}
    .menu li a{
      height:50px;
      left:0px;
      width:100%;
      .konsole-icon{
        font-size:1.4em;
        left:20px !important;
      }
    }
  }
  @extend %transition-all;
  &.closed{
    width:0;
  }
  .avatar{
    height: 85px;
    //background: $colorful-custom;
    width:280px;
    background: darken($sidebar-color, 5%);
    .logo{
      padding: 0.7em 2.4em;
      img{
        max-width: 100%;
      }
      .logoMobile{
        display:none;
      }
    }
    .profil-img{
      $sizeImg: 100%;
      width: $sizeImg;
      height: $sizeImg;
      overflow: hidden;
      margin: 0;
      @extend %border-radius-round;
      .wrap{
        text-align: center;
        width: 45px;
        height: 45px;
        background: #FFF;
        @extend %border-radius-round;
        i{
          //color: desaturate(lighten($colorful-custom, 20%), 60%);
          //color: desaturate(lighten($sidebar-color, 20%), 60%);
          color: $sidebar-color;
          line-height: 45px;
        }
        img{
          width: 100%;
        }
      }
    }
    .name-wrap{
      position: relative;
      margin: 0 0 0 $spacer;
      .name, .subtitle{
        display: block;
        color: $light-color;
        font-size: 1.2em;
        font-weight: $fw-semibold;
      }
      .subtitle{
        font-size: 1em;
        font-weight: $fw-light;
      }
      button{
        border: 0;
        padding: 0;
        margin: 0;
        background: none;
        $width: 20px;
        $height: 22px;
        width: $width;
        height: $height;
        color: $light-color;
        position: absolute;
        right: 0;
        top: 50%;
        margin-top: -(calc($height/2));
      }
    }
  }
  .sidebar-scroll{
    padding-top: $spacer;
    padding-bottom: $heightNavbar+$spacer+45px;;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
  }
  // .menu>li.active>a{
  //   $borderSize: 4px;
  //   $padder: 5px;
  //   $padderLarge: $padder+3;
  //   $padderSmall: $padderLarge - $borderSize;
  //             color: $light-color;
  //             padding: $padder $padderLarge $padder $padderSmall;
  //             background: darken($sidebar-color, 50%);
  //             .konsole-icon{
  //               left: 11px;
  //             }
  // }
  .menu{
    $borderSize: 4px;
    $padder: 5px;
    $padderLarge: $padder+3;
    $padderSmall: $padderLarge - $borderSize;
    list-style: none;
    margin: 0;
    padding: 0;
    .icon-SideBar-Mobile{
      cursor: pointer;
      position:absolute;
      bottom:0;
      width:calc(100% - 17px);
      background-color:#1f2226;
      @extend %transition-all;
    }
    .icon-SideBar-Mobile i{@extend %transition-all;}
    .rotateLeft{
      -ms-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    >li.active>a{
      color: $light-color;
      padding: $padder $padderLarge $padder $padderSmall;
      background: darken($sidebar-color, 50%);
      .konsole-icon{
        left: 11px;
      }
    }
    li{
      .subMenu{
        max-height:250px;
        overflow:hidden;
        @extend %transition-all;
        &.none{
          max-height:0px;
        }
        li{
          &.active>a{
            color: $light-color;
            padding: $padder $padderLarge $padder $padderSmall;
            background: darken($sidebar-color, 50%);
            .konsole-icon{
              left: 11px;
            }
          }
          a{
            .konsole-icon{
              font-size: 0.8em;
            }
          }
        }
      }
      a{
        $konsole-icon-size: 22px;
        position: relative;
        $height: 60px;
        display: block;
        height: auto;
        line-height: calc($height/2);
        padding: $padder ($padder+3);
        //color: darken($light-color, 40%);
        color: lighten($base-color, 40%);
        .name > .fa{
          margin-left: 1em;
        }
        .konsole-icon{
          font-size: 0.9em;
          display: inline-block;
          vertical-align: middle;
          width: $konsole-icon-size;
          height: $konsole-icon-size;
          position: absolute;
          top: 50%;
          left: $spacer;
          margin-top: -(calc($konsole-icon-size/2));
          i{
            font-size: 1.5em;
            position: relative;
            top: -3px;
          }
        }
        .toggler{
          font-size: 0.9em;
          position: absolute;
          display: inline-block;
          vertical-align: middle;
          padding-right:1em;
          top: 60%;
          right: 0;
          width: $konsole-icon-size;
          height: $konsole-icon-size;
          margin-top: -(calc($konsole-icon-size/2));
          text-align: right;
        }
        .name{
          width: 100%;
          display: block;
          vertical-align: middle;
          padding-left: $konsole-icon-size+15;
          font-weight: $fw-semibold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &.special{
          cursor:pointer;
        }
        &:hover, &:focus{
          background: darken($sidebar-color, 5%);
        }
      }
      &.disabled{
        @extend %opacity-25;
        background: $sidebar-color;
        a{
          &:hover, &:focus{
            cursor: default;
            background: $sidebar-color;
          }
        }
      }
    }
  }
}

.avatar, .navbar .inner{
  padding: 15px $spacer;
}

.navbar{
  position: relative;
  height: $heightNavbar;
  width: 100%;
  @extend %transition-all;
  //background: $sidebar-color;
  //background: darken($sidebar-color, 5%);
  background: $colorful;
  background: rgba(saturate(darken($colorful, 2%), 5%), 1);
  top: 0;
  left: 0;
  border: 0;
  border-radius: 0;
  -moz-box-shadow:    0 4px 2px -2px rgba($dark-color,0.5);
  -webkit-box-shadow: 0 4px 2px -2px rgba($dark-color,0.5);
  box-shadow:         0 4px 2px -2px rgba($dark-color,0.5);
  &.fixed{
    position: fixed;
    z-index: 103;
    //-webkit-box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
    //box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
  .inner{
    //padding: 15px $spacer*2;
    padding: 0;
  }
  .top-menu-btn{
    width:47px;
    height:26px;
    display:inline-block;
    position:relative;
    float:left;
    border-radius:5px;
    padding:14px 13px 26px;
    background-color:#0c96d1;
    margin-top:12px;
    margin-left:20px;
    cursor:pointer;
    @extend %transition-all;
    .rectangles{
      width:100%;
      height:2px;
      background-color:$light-color;
      margin-bottom:3px;
      display:block;
    }
    &:hover{
      background-color:#1f2226;
    }
  }
  .logo{
    position: relative;
    height: $heightNavbar;
    display: table;
    color: $light-color;
    letter-spacing: 0.05em;
    float:left;
    display:inline-block;
    margin-left:30px;
    padding-top:5px;
    .title{
      font-weight: $fw-bold;
      color: $light-color;
      font-size: 1.5em;
      text-transform: uppercase;
    }
    i{
      padding: 0 0.5em;
      font-weight: $fw-bold;
    }
    .name{
      font-weight: $fw-bold;
    }
    .badge{
      color: $colorful;
      background: $light-color;
      font-size: 0.8em;
    }
    .role{
      color: $light-color;
    }
    img{
      max-width: 100%;
      vertical-align: middle;
    }
  }
  .settings{
    float: right;
    height: $heightNavbar;
    ul{
      position: relative;
      height: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        display: inline;
        display: inline-block;
        zoom: 1;
        height: 100%;
        //margin-left: $spacer*2;
        a{
          display: inline;
          display: inline-block;
          zoom: 1;
          vertical-align: middle;
          padding: 1.54em 0;
          height: 100%;
          width: $heightNavbar;
          text-align: center;
          //border-left: 1px solid lighten($console-color,1%);
          //border-left: 1px solid #000;
          color: $light-color;
          //background: $light-color;
          //background: darken($sidebar-color, 5%);
          //background: $colorful;
          .ico{
            position: relative;
            display: inline-block;
            i{
              position: relative;
              $width: 28px;
              $height: 28px;
              width: $width;
              height: $height;
              display: inline-block;
              vertical-align: middle;
              //font-size: 2.4em;
              font-size: 1.875em !important;
            }
            .label{
              display: block;
              $borderSize: 2px;
              $size: 24px;
              $lineHeight: $size - ($borderSize*2);
              position: absolute;
              top: -8px;
              left: -14px;
              background: $notif-label-color;
              width: $size;
              height: $size;
              line-height: $lineHeight;
              padding: 0;
              z-index: 10;
              border: $border-size solid $light-color;
              font-weight: $fw-semibold;
              opacity: 0;
              @extend %border-radius-round;
              @extend %transition-all;
              -webkit-transform: scale(0);
                  -ms-transform: scale(0);
                      transform: scale(0);
            }
          }
          span.name{
            display: inline-block;
            margin-left: calc($spacer/2);
            font-size: 1em;
            font-weight: $fw-semibold;
            vertical-align: middle;
          }
          &:hover, &:focus{
            color: $dark-color;
          }
          &.notif-on{
            .ico{
              .label{
                opacity: 1;
                -webkit-transform: scale(1);
                    -ms-transform: scale(1);
                        transform: scale(1);
              }
            }
          }
        }
      }
    }
  }
}

.body{
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: $width-sidebar;
  margin-top: $heightNavbar;
  @extend %transition-all;
  .main-page{
    background-color:#e8ebed;
    padding:25px 25px;
    form.form-horizontal{
      padding:5px 45px;
      background-color:#FFF;
      box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    }
    .dataTables_info{margin:0;}
    .dataTables_length label{
      margin:20px 0 0;
    }
  }
  .dataTables_wrapper{
    box-shadow:0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    background-color:#FFF;
    padding:25px;
  }
  &.small{
    padding-left:75px;
  }
  &.closed{
    width:100%;
    padding-left:0;
  }
  .c-canvas{
    padding: 0;
    //padding: $spacer*2;
    .c-inner{
      background-color:#e8ebed;
      @extend %border-radius;
      padding: 0;
      overflow: scroll;
      min-height: 85vh;
    }
  }
  .header-page .actions .btn.btn-sm{padding:11px;}
  .c-sub-menu{
    //margin-top: -5px;
    //background: $lightbluegrey;
    background: lighten($lightbluegrey, 5%);
    //padding: $spacer $spacer 0;
    position:relative;
    top:20px;
    left:50px;
    ul{
      margin: 0;
      padding: 0;
      position: relative;
      bottom: -5px;
      li{
        //display: block;
        vertical-align: baseline;
        //float: left;
        margin-right: 0.5%;
        display: inline-block;
        a{
          display: block;
          background: $light-color;
          padding: 1.5em 0.8em;
          -webkit-border-top-left-radius: $border-radius;
          -webkit-border-top-right-radius: $border-radius;
          -moz-border-radius-topleft: $border-radius;
          -moz-border-radius-topright: $border-radius;
          border-top-left-radius: $border-radius;
          border-top-right-radius: $border-radius;
        }
        &.active{
          a{
            color: $colorful;
          }
        }
      }
    }
  }
  .c-footer{
    text-align: center;
    p{margin:0;}
  }

  .notes{
    text-align: center;
    padding: 4em 0;
    border: 1px solid $lightbluegrey;
    color: darken($lightbluegrey, 14%);
    @extend %border-radius;
    margin-bottom: $spacer*2;
  }
}


// CONTENT PAGES

.header-page{
  margin: 0;
  padding: 15px 20px;
  -moz-box-shadow:    0 1px 1px -1px rgba(0, 0, 0, 0.34), 0 0 6px 0 rgba(0, 0, 0, 0.14);
  -webkit-box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.34), 0 0 6px 0 rgba(0, 0, 0, 0.14);
  box-shadow:         0 1px 1px -1px rgba(0, 0, 0, 0.34), 0 0 6px 0 rgba(0, 0, 0, 0.14);
  //border-bottom: 1px solid $base-color;
  //background: $lightbluegrey;
  background: #FFF;
  position:relative;
  .wrap-title{
    float: left;
  }
  .title{
    font-size: 1.8em;
    line-height: 1.5em;
    display: inline-block;
    vertical-align: middle;
  }
  .subtitle{
    position: relative;
    top: 2px;
    font-weight: $fw-light;
    display: inline-block;
    font-size: 1.4em;
    vertical-align: middle;
    margin-left: $spacer;
    i{
      margin-right: $spacer;
    }
  }
  .actions{
    float: right;
    position:relative;
    top:20px;
    .btn{
      width: 38px;
      height: 38px;
      margin-left: $spacer;
    }
  }
}

.cardSection{margin-top:2em;}
.sectionButton{margin-top:2em;}
.cardLogo{
  margin-bottom:2em;
}
.cardLogo.title{float:right;}

.main-page{
  padding: $spacer*2;
}


.breadcrumb{
  margin: 0;
  background: none;
  padding: 5px 30px 5px 0;
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    li{
      display: inline-block;
      //color: $title-color;
      color: darken($lightbluegrey, 14%);
      font-size: 0.95em;
      i{
        padding: 0 0.5em;
      }
      a, span{
        display: inline-block;
      }
      span{}
      &:last-child{
        i{
          display: none;
        }
      }
    }
  }
}
.adressForm{
  margin-left:26%;
}



/////// DATA TABLES NEW LAYOUT OVERRIDE //////////////
//////////////////////////////////////////////////////


.filter-search{
  //margin: $spacer*2;
  padding: $spacer $spacer*2;
  margin: 0 0 0.5em;
  //border: 1px solid $lightbluegrey;
  //@extend %border-radius-round;
  background: lighten($lightbluegrey, 5%);
  .dataTables_wrapper .dataTables_length{
    margin: 0.5em 0;
  }
  .dataTables_wrapper .dataTables_length, .dataTables_wrapper .dataTables_filter{
    label{
      margin-bottom: 0;
    }
    input{
      &[type="search"], &[type="text"]{
        @extend %border-radius-round;
        background: $lightbluegrey;
        background: #FFF;
        border: 0;
        color: $title-color;
        padding: 1em 4em 1em 1.5em !important;
      }
    }
  }
  .dataTables_wrapper .dataTables_filter{
    position: relative;
    i{
      $size: 20px;
      width: $size;
      height: $size;
      position: absolute;
      top: 50%;
      margin-top: -(calc($size/2));
      right: calc($spacer*1.5);
      pointer-events: none;
      font-size: 1.4em;
      color: $title-color;
    }
  }
}

.dataTables_wrapper .dataTables_filter input{
  &, &[type="search"]{
    &, &:focus{
      outline: none;
    }
  }

  &[type="search"]{

  }
}

input{
  &[type="text"],&[type="search"]{
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    padding: 0.75em 1em !important;
    height: auto !important;
  }
}

.dataTables_length{
  label{
    select{
      margin-left: 0.5em;
    }
  }
}

table.dataTable{
  border-collapse: collapse;
}

.dataTables_wrapper{
  thead{
    th{
      //border-top: 0 !important;
      padding: 1.5em 1.5em !important;
      border: 1px solid $lightgreyborder !important;
      //border-bottom: 0 !important;
      border-collapse: collapse;
      color: $base-color;
      &.sorting_disabled{
        color: darken($lightbluegrey, 30%);
        background: #FFF !important;
      }
    }
  }
  table.table-gray tbody > tr > th, table.table-gray tbody > tr{
    > td{
      background: #FFF;
      border-top: 0;
      border-bottom: 1px solid $lightgreyborder;
      border-collapse: collapse;
      @extend %transition-all;
    }
    &:hover, &:focus{
      td{
        background: lighten($lightbluegrey, 8%);
      }
    }
  }
  table.dataTable tbody th, table.dataTable tbody td{
    padding: $spacer;
  }
}

.sorting_disabled{
  color: darken($lightbluegrey, 30%);
  background: #FFF !important;
}

.sorting_1{
  font-weight: $fw-normal;
  background: lighten($lightbluegrey, 8%) !important;
}
